<template>
    <div v-if="!noValues" class="monthly-holiday-entry">
        <bar v-show="!addedLeaveOnly" :min="0" :max="entitled" :value="currentMonth"></bar>
        <div v-show="!addedLeaveOnly" class="label entitled" v-bind:class="{green: entitled > 0, red: entitled < 0, gray: entitled === 0}">{{labelEntitled}}</div>
        <div v-show="!addedLeaveOnly" class="label current-month" v-bind:class="{green: -currentMonth > 0, red: -currentMonth < 0, gray: -currentMonth === 0}">{{labelCurrentMonth}}</div>
        <div class="label added-leave" v-bind:class="{only: addedLeaveOnly, green: addedLeave > 0, red: addedLeave < 0, gray: addedLeave === 0}">{{labelAddedLeave}}</div>
    </div>
</template>

<script>
    export default {
        name: "monthlyholidayentry",
        data(){
            return{
                entitled: 0,
                addedLeave: 0,
                currentMonth: 0,
                addedLeaveOnly: false
            }
        },
        computed:{
            noValues(){
                return this.entitled == 0 && this.currentMonth == 0;
            },
            labelEntitled(){
                let label = this.entitled < 0 ? '' : '+';
                label += (Math.round(this.entitled * 100) / 100).toFixed(2);
                label.replace('.', ',');
                return label;
            },
            labelAddedLeave(){
                let label = this.addedLeave < 0 ? '' : '+';
                //label += (Math.round(this.addedLeave * 100) / 100).toFixed(2);
                label = (Math.round(this.addedLeave * 100) / 100).toFixed(2);
                label.replace('.', ',');
                return label;
            },
            labelCurrentMonth(){
                let label = -this.currentMonth < 0 ? '' : '+';
                //label += (Math.round(-this.currentMonth * 100) / 100).toFixed(2);
                label = (Math.round(-this.currentMonth * 100) / 100).toFixed(2);
                label.replace('.', ',');
                return label;
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        props:{
            state: Object,
        },
        methods:{
        }
    }
</script>

<style scoped>
    .monthly-holiday-entry{
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .monthly-holiday-entry .bar, .monthly-holiday-entry .entitled, .monthly-holiday-entry .added-leave, .monthly-holiday-entry .current-month{
        position: absolute;
        height: 50%;
        width: 50%;
        padding: 0 0px;
        font-size: 11px;
    }
    .monthly-holiday-entry .bar{
        top: 8px;
        left: 3px;
        height: 6px;
        width: calc(50% - 10px);
        padding: 0;
    }

    .monthly-holiday-entry .entitled{
        top: 4px;
        right: 0;
        text-align: right;
    }
    .monthly-holiday-entry .current-month{
        bottom: 0;
        left: 0;
        text-align: left;
    }
    .monthly-holiday-entry .added-leave{
        bottom: 0;
        right: 0;
        text-align: right;
    }

    .added-leave.only{
        height: 55%;
        width: 85%;
        font-size: 13px !important;
    }

    .monthly-holiday-entry .green{
        font-family: DistrictProBold;
        color: #6c8d25;
    }

    .monthly-holiday-entry .red{
        color: var(--col-red-text);
        font-weight: 200;
        font-family: DistrictProLight;
    }
    .monthly-holiday-entry .gray{
        /*color: var(--contrast-3);*/
        font-weight: 200;
        font-family: DistrictProLight;
    }

    .row:hover .monthly-holiday-entry .gray{
        /*color: var(--contrast-7);*/
    }
</style>